

import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '@/lib/abpbase'
import Maquina from '@/store/entities/OZONE/Franqueo/maquinas';

@Component({components: {}})
export default class FranqueoMaquinas extends AbpBase{
    
    maquinaIsActive: Boolean = true;
    maquinas = [];

    // get list(){
    //     return this.$store.state.franqueo.maquinasList;
    // }

    get loading() {
        return this.$store.state.franqueo.loading;
    }

    async created(){
        await this.getPage();
    }

    async getPage(){
        await this.$store.dispatch({
            type: 'franqueo/getMaquinas'
        }).then(value => {
            this.maquinas = value;
        });
    }
    
    async changeEstadoMaquina(row){
        console.log(row);
        await this.$store.dispatch({
            type: 'franqueo/editMaquina',
            data: row
        })
    }

    // async changeEstadoMaquina(maquina){
    //     await this.$store.dispatch({
    //         type: 'franqueo/editMaquinaEstado',
    //         payload: maquina
    //     });
    //     await this.getPage();
    // }

    // async onCellChange(key, dataIndex, value) {
    //   const dataSource = [...this.list];
    //   const target = dataSource.find(item => item.key === key);
    //   if (target) {
    //     target[dataIndex] = value;
    //     //await this.changeEstadoMaquina(target);
    //     // this.list = dataSource;
    //   }
    // }

    columns = [
        {
            title: this.L('Máquina'),
            dataIndex: 'id',
            thClass: 'w-auto',
            tdClass: 'w-auto',
            width: 8
        },
        {
            title: this.L('Operativa'),
            key: 'isActive',
            scopedSlots: { customRender: "onOff" },
            width: 8
        },
        {
            title: this.L('Bloqueo'),
            dataIndex: 'bloqueo',
            width: 8
        }
    ];
}

